<template>
  <div class="job-description">
    <div class="top" data-aos="fade-in">
      <img src="@/assets/teamleft.webp" alt="technomasr" />
      <div class="content">
        <div class="container position-relative title-fade-in">
          <h2 class="main-title fs-2 mb-0">Junior Back-End Developer</h2>
          <p class="withbg"></p>
        </div>
      </div>
    </div>
    <div class="container mt-5">
      <div class="row">
        <div class="col-md-7">
          <h3 class="secondary-color mb-5">Junior Back-End Developer</h3>
          <div class="details mt-5">
            <!-- <h5 class="secondary-color">وصف الوظيفه</h5> -->
            <p></p>
            <p>
              <span style="color: #003366;">
                <strong>Job Duties and Responsibilities:</strong>
              </span>
            </p>
            <ul style="list-style-type: circle;">
              <li>
                Be involved and participate in the overall application
                lifecycle.
              </li>
              <li>Main focus on coding and debugging.</li>
              <li>Collaborate with Frontend &amp; Mobile Apps Developers.</li>
              <li>Define and communicate technical and design requirements.</li>
              <li>
                Build high-quality reusable code that can be used in thew
                future.
              </li>
              <li>
                Develop functional and sustainable web applications with clean
                codes.
              </li>
              <li>Troubleshoot and debug applications.</li>
              <li>Learn about new technologies.</li>
              <li>Stay up to date with current best practices.</li>
              <li>Conduct UI tests and optimize performance.</li>
              <li>Manage cutting-edge technologies to improve applications.</li>
              <li>
                Collaborate with multidisciplinary team of designers, developers
                and system administrators.
              </li>
              <li>Follow new and emerging technologies.</li>
            </ul>
            <p>
              <span style="color: #003366;">
                <strong>Job Requirements:</strong>
              </span>
            </p>
            <ul style="list-style-type: circle;">
              <li>Bachelor's degree in Computer Science, Engineering,</li>
              <li>
                Prefer to have more than 1 years of experience in PHP Laravel
                framework.
              </li>
              <li>
                <span style="text-decoration: underline;">
                  <span style="color: #ff0000; text-decoration: underline;">
                    Mansoura Residents only can apply.
                  </span>
                </span>
              </li>
            </ul>
            <p>
              <span style="color: #003366;"><strong>Job Details:</strong></span>
            </p>
            <ul style="list-style-type: circle;">
              <li>
                <span style="color: #ff0000;">Job Location (Mansoura).</span>
              </li>
              <li>Career Level (Junior Level).</li>
              <li>Military service (Not required)</li>
              <li>Bachelor's degree</li>
            </ul>
            <p></p>
          </div>
        </div>
        <div class="col-md-5">
          <div class="txt p-4 border">
            <h5>
              We are looking for an experienced Back-end developer to join our
              IT team! As a Junior Back-end Developer
            </h5>
            <br />
            <ul class="p-0 info list-unstyled" dir="ltr">
              <li>
                <span><font-awesome-icon :icon="['fas', 'bookmark']" /></span>
                9:00am : 5:00 pm - Sunday to Thursday
              </li>

              <li>
                <span><font-awesome-icon :icon="['fas', 'bookmark']" /></span>
                Employment Type : Full Time
              </li>
            </ul>
            <div class="text-center">
              <a
                href="https://technomasr.com/JobOpportunities/2/ApplyForJob/التقديم-علي-وظيفه"
                class="btn btn-primary text-white m-t-30 p-20"
              >
                Apply Now
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style scoped lang="scss">
.job-description {
  direction: ltr;
  .row {
    .txt {
      ul {
        margin-bottom: 50px;
        li {
          margin-bottom: 10px;
          span {
            color: #a7d323;
            margin-left: 3px;
          }
        }
      }
    }
  }
}
</style>
